/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { last, sortBy } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  moveWorkflowStatus,
  moveWorkflowStatusWithVersions,
  newDocumentHistory,
  printDocumentHistory
} from '../../components/feature-flags'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import { Popover2 } from '../../ui/popover'
import {
  DocumentQueryContext,
  getWorkflowSteps
} from '../action/workflow-tracker'
import RefreshButton from '../action/workflow-tracker/refresh-button'
import { useRefreshSimulation } from '../action/workflow-tracker/use-refresh-simulation'
import { useWorkflowOverview } from '../action/workflow-tracker/utils'

export const Button = ({ animate, onClick }) => {
  return (
    (newDocumentHistory || moveWorkflowStatus) && (
      <button
        className={cx(
          'flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100',
          animate && 'kp-glow-orange-100'
        )}
        data-testid='history-button'
        onClick={onClick}
      >
        <Icons.History />
        <span>
          {newDocumentHistory ? (
            <Trans id='document.history' />
          ) : (
            <Trans id='pagesrunner.workflow.status' />
          )}
        </span>
      </button>
    )
  )
}

export const View = ({
  documentHistory,
  documentQuery,
  hide,
  isVisible,
  width
}) => {
  const closeButtonRef = useRef(null)
  useEffect(() => {
    if (closeButtonRef.current) {
      setTimeout(() => closeButtonRef.current.focus())
    }
  }, [isVisible])
  const {
    appId,
    documentId,
    hasVersions,
    hasWFTroubleshootPerms,
    simulation,
    versions = []
  } = documentHistory
  const [workflowOverview, updateWorkflowOverview] =
    useWorkflowOverview(simulation)
  const [refreshSimulation, refreshError] =
    useRefreshSimulation(DocumentQueryContext)
  const [refreshing, setRefreshing] = React.useState(false)
  const alerts = useAlerts()
  React.useEffect(() => {
    if (simulation) {
      updateWorkflowOverview({ simulation })
    }
  }, [simulation])
  if (refreshError) {
    alerts.type3(refreshError, 'error')
  }
  const isComplete = ['Complete', 'Withdrawn', 'Denied'].includes(
    workflowOverview?.status
  )
  const [selectedOption, setSelectedOption] = React.useState('all')
  const [showAllVersions, setShowAllVersions] = React.useState(false)
  const [showFutureSteps, setShowFutureSteps] = React.useState(true)
  const [expandOrCollapseAll, setExpandOrCollapseAll] = React.useState('none')
  const panelWidth = width <= 1024 ? 'w-full' : 'w-[440px]'
  const topStyle =
    width <= 1024 ? 'top-0 z-[10] h-full' : 'z-[2] h-[calc(100%-64px)]'
  const SyncWorkflowButton = (
    <div className='rounded border border-light-gray-400'>
      <RefreshButton
        label={<Trans id='pagesrunner.workflow.sync' />}
        refreshing={refreshing}
        refreshSimulation={
          hasWFTroubleshootPerms && !isComplete
            ? async () => {
                try {
                  setRefreshing(true)
                  await refreshSimulation(
                    appId,
                    documentId,
                    updateWorkflowOverview
                  )
                  alerts.type3(i18n._('pagesrunner.workflow.updated'), 'info')
                } catch (error) {
                  alerts.type3(error, 'error')
                } finally {
                  setRefreshing(false)
                }
              }
            : () => {}
        }
      />
    </div>
  )
  let _workflowOverview = maybeAddWorkflowCompleteStep(workflowOverview)
  if (showAllVersions && versions.length > 1) {
    let simulationSteps = workflowOverview?.steps
    const sortedVersions = sortBy(versions, v => v.meta?.versionNumber)
    simulationSteps = sortedVersions.reduce((acc, v) => {
      if (v.workflow?.simulation?.steps) {
        const stepsToAdd =
          v.id === documentId
            ? workflowOverview.steps
            : maybeAddWorkflowCompleteStep(v.workflow.simulation).steps

        return acc.concat(
          stepsToAdd.map(s => ({
            ...s,
            versionNumber: v.meta?.versionNumber
          }))
        )
      }
      return acc
    }, [])
    _workflowOverview = {
      ...workflowOverview,
      stepsWithAllVersions: simulationSteps
    }
  }
  const MainElement = (
    <TransitionGroup>
      <CSSTransition key={isVisible ? 'history' : null} timeout={500}>
        {isVisible && documentHistory?.appId ? (
          <div
            id='history-panel'
            className={`kp-slide-in-from-right absolute right-0 print:hidden ${topStyle} ${panelWidth} overflow-auto bg-white transition-[transform] duration-500`}
          >
            <div className='sticky top-0 z-10 flex flex-col items-start gap-2.5 self-stretch border-light-gray-300 bg-light-gray-200 p-4'>
              <div className='flex w-full flex-row items-center justify-between'>
                <div className='mr-4 text-base font-medium text-black'>
                  {newDocumentHistory ? (
                    <Trans id='document.history' />
                  ) : (
                    <Trans id='pagesrunner.workflow.status' />
                  )}
                </div>
                {!moveWorkflowStatusWithVersions && SyncWorkflowButton}
                <div className='flex flex-1 justify-end print:hidden'>
                  <button
                    className='kp-button-transparent kp-button-icon kp-button-lg m-0 h-4 p-0 text-medium-gray-500 dark:text-medium-gray-300'
                    onClick={hide}
                    aria-label={
                      moveWorkflowStatus
                        ? i18n._('close.workflow.status')
                        : i18n._('close.document.history')
                    }
                    ref={closeButtonRef}
                  >
                    <Icons.Close className='h-4 w-4' />
                  </button>
                </div>
              </div>

              {moveWorkflowStatusWithVersions && (
                <div className='flex w-full flex-row items-center justify-between'>
                  {SyncWorkflowButton}
                  <OptionMenu
                    hasVersions={hasVersions}
                    setExpandOrCollapseAll={setExpandOrCollapseAll}
                    setShowAllVersions={setShowAllVersions}
                    setShowFutureSteps={setShowFutureSteps}
                    showAllVersions={showAllVersions}
                    showFutureSteps={showFutureSteps}
                  />
                </div>
              )}
              {newDocumentHistory && (
                <div className='flew-row flex items-start gap-4 text-base text-medium-gray-500'>
                  <button
                    className={
                      selectedOption === 'all'
                        ? 'border-b-2 border-blue-500 text-black'
                        : ''
                    }
                    onClick={() => setSelectedOption('all')}
                  >
                    <Trans id='all.activity' />
                  </button>
                  <button
                    className={
                      selectedOption === 'edits'
                        ? 'border-b-2 border-blue-500 text-black'
                        : ''
                    }
                    onClick={() => setSelectedOption('edits')}
                  >
                    <Trans id='edits' />
                  </button>
                  <button
                    className={
                      selectedOption === 'workflow'
                        ? 'border-b-2 border-blue-500 text-black'
                        : ''
                    }
                    onClick={() => setSelectedOption('workflow')}
                  >
                    <Trans id='workflow' />
                  </button>
                  <button
                    className={
                      selectedOption === 'email'
                        ? 'border-b-2 border-blue-500 text-black'
                        : ''
                    }
                    onClick={() => setSelectedOption('email')}
                  >
                    <Trans id='email' />
                  </button>
                </div>
              )}
            </div>
            <div>
              <HistoryList
                documentHistory={documentHistory}
                expandOrCollapseAll={expandOrCollapseAll}
                selectedOption={selectedOption}
                showFutureSteps={showFutureSteps}
                updateWorkflowOverview={updateWorkflowOverview}
                workflowOverview={_workflowOverview}
              />
            </div>
          </div>
        ) : (
          <span />
        )}
      </CSSTransition>
    </TransitionGroup>
  )

  return documentQuery ? (
    <DocumentQueryContext.Provider value={{ query: documentQuery }}>
      {MainElement}
    </DocumentQueryContext.Provider>
  ) : (
    MainElement
  )
}

function maybeAddWorkflowCompleteStep (workflowOverview) {
  // if workflowOverview has a status of Complete or Rejected, add a step to the end of the steps array
  if (['Complete', 'Rejected'].includes(workflowOverview?.status)) {
    const msg = `Workflow ${workflowOverview.status}`
    const completeStep = {
      completedAt: last(workflowOverview.steps)?.completedAt,
      msg,
      status: msg,
      type: 'workflowComplete',
      versionNumber: workflowOverview.versionNumber
    }
    return {
      ...workflowOverview,
      steps: [...workflowOverview.steps, completeStep]
    }
  }
  return workflowOverview
}

function OptionMenu ({
  hasVersions,
  setExpandOrCollapseAll,
  setShowAllVersions,
  setShowFutureSteps,
  showAllVersions,
  showFutureSteps
}) {
  return (
    <div className='rounded border border-light-gray-400'>
      <Popover2
        role='menu'
        trigger={
          <button className='kp-button-transparent'>
            <Icons.Settings className='mr-2' />
            <Trans id='pagesrunner.history.view' />
          </button>
        }
        top='36'
        right='4'
      >
        {close => (
          <ul className='p-2 dark:bg-dark-gray-400'>
            {hasVersions && (
              <li>
                <button
                  className='kp-button-transparent kp-button-icon w-full justify-start gap-4 text-medium-gray-500'
                  onClick={() => {
                    setShowAllVersions(!showAllVersions)
                  }}
                >
                  <Icons.History className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='show.all.versions' />
                  </span>
                  <span className='flex-grow' />
                  {showAllVersions && <Icons.Check />}
                </button>
              </li>
            )}
            <li>
              <button
                className='kp-button-transparent kp-button-icon gap-4 text-medium-gray-500'
                onClick={() => {
                  setShowFutureSteps(!showFutureSteps)
                }}
              >
                <Icons.Hourglass className='dark:fill-medium-gray-300' />
                <span>
                  <Trans id='show.future.steps' />
                </span>
                <span className='flex-grow' />
                {showFutureSteps ? <Icons.Check /> : <span className='w-4' />}
              </button>
            </li>
            <li>
              <button
                className='kp-button-transparent kp-button-icon w-full text-medium-gray-500'
                onClick={() => {
                  setExpandOrCollapseAll('expand')
                }}
              >
                <span className='flex w-full justify-start gap-4'>
                  <Icons.SelectDownArrow className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='expand.all' />
                  </span>
                </span>
              </button>
            </li>
            <li>
              <button
                className='kp-button-transparent kp-button-icon w-full text-medium-gray-500'
                onClick={() => {
                  setExpandOrCollapseAll('collapse')
                }}
              >
                <span className='flex w-full justify-start gap-4'>
                  <Icons.SelectUpArrow className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='collapse.all' />
                  </span>
                </span>
              </button>
            </li>
            {printDocumentHistory && (
              <li>
                <div className='border-t border-light-gray-300 dark:border-dark-gray-300' />
                <button
                  className='kp-button-transparent kp-button-icon gap-4 text-medium-gray-500'
                  onClick={() => close()}
                >
                  <Icons.Print className='dark:fill-medium-gray-300' />
                  <span>
                    <Trans id='print' />
                  </span>
                </button>
              </li>
            )}
          </ul>
        )}
      </Popover2>
    </div>
  )
}

function HistoryList ({
  selectedOption,
  documentHistory,
  expandOrCollapseAll,
  showFutureSteps,
  updateWorkflowOverview,
  workflowOverview
}) {
  const {
    appId,
    documentId,
    simulation,
    hasWFTroubleshootPerms,
    workflowResends,
    ...props
  } = documentHistory
  const hasSimulationSteps = (simulation?.steps || []).length > 1
  switch (selectedOption) {
    case 'all':
      return hasSimulationSteps ? (
        getWorkflowSteps(workflowOverview, {
          appId,
          documentId,
          expandOrCollapseAll,
          hasWFTroubleshootPerms,
          showFutureSteps,
          updateWorkflowOverview,
          workflowResends,
          ...props
        })
      ) : (
        <div>
          <Trans id='document.history' />
        </div>
      )
    case 'edits':
      return <div>Coming soon!</div>
    case 'workflow':
      return hasSimulationSteps ? (
        <div>
          {getWorkflowSteps(workflowOverview, {
            appId,
            documentId,
            expandOrCollapseAll,
            hasWFTroubleshootPerms,
            showFutureSteps,
            updateWorkflowOverview,
            workflowResends,
            ...props
          })}
        </div>
      ) : (
        <div>
          <Trans id='no.workflow.history' />
        </div>
      )
    case 'email':
      return <div>Coming soon!</div>
    default:
      return <div>Nothing to see here!</div>
  }
}
